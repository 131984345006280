import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
// import { LazyLoadComponent } from "react-lazy-load-image-component"
// import imagePlaceholder from "../../images/image-placeholder.jpg"

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

// const heroSlides = [
// 	{
// 		title: "Home Page Slider Title Text Goes Here",
// 		description:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
// 		image: imagePlaceholder,
// 		link: "/",
// 		backgroundImage: slide1BG,
// 	},
// 	{
// 		title: "Home Page Slider Title Text Goes Here",
// 		description:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
// 		image: imagePlaceholder,
// 		link: "/",
// 		backgroundImage: slide2BG,
// 	},
// 	{
// 		title: "Home Page Slider Title Text Goes Here",
// 		description:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
// 		image: imagePlaceholder,
// 		link: "/",
// 		backgroundImage: slide1BG,
// 	},
// 	{
// 		title: "Home Page Slider Title Text Goes Here",
// 		description:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
// 		image: imagePlaceholder,
// 		link: "/",
// 		backgroundImage: slide2BG,
// 	},
// ]

class Hero extends React.Component {
  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1000,
      loop: false,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    }
    const { slider } = this.props

    return (
      <div className="hero-slider-section">
        <div className="hero-slider-wrapper">
          <Swiper {...params}>
            {/*slide*/}
            {slider.map(slide => (
              <div className="slide-wrapper" key={slide.node.id}>
                <div className="container">
                  <div>
                    <div className="row align-items-end hero-slider-row">
                      <div className="col-lg-6 col-md-7 text-center text-md-left">
                        <div>
                          <h2 className="hero-title-text font-weight-normal">
                            {slide.node.title}
                          </h2>
                          {slide.node.description ? (
                            <p className="hero-description-text">
                              {slide.node.description}
                            </p>
                          ) : null}

                          {slide.node.pagelinkname ? (
                            <Link
                              to={
                                slide.node.pagelink
                                  ? slide.node.pagelink.current
                                  : "/"
                              }
                              className="btn hero-button"
                            >
                              {slide.node.pagelinkname}
                            </Link>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-5 d-none d-md-block">
                        <div>
                          {slide?.node?.productImage?.asset?.fluid ? (
                            <Img
                              className="hero-image"
                              fluid={slide?.node?.productImage?.asset?.fluid}
                              alt={slide.node.title}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="slide-bg"
                  style={{
                    backgroundImage: `url(${slide?.node?.banner?.asset?.fluid?.src})`,
                  }}
                ></div>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    )
  }
}

export default Hero
