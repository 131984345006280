import React from "react"
import { Link } from "gatsby"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import BlockContent from "@components/block/block"
import slugify from "slugify"
import Img from "gatsby-image"

class FeaturedProducts extends React.Component {
	render() {
		const params = {
			speed: 1000,
			autoplay: false,
			spaceBetween: 10,
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				480: {
					slidesPerView: 2,
				},
				640: {
					slidesPerView: 3,
				},
				760: {
					slidesPerView: 4,
				},
			},
		}

		return (
			<div className="featured-products-section">
				<div className="container-fluid position-relative">
					<div className="featured-products-swiper-wrapper">
						<LazyLoadComponent>
							<Swiper {...params}>
								{this.props.products.map(prod => {
									const slug =
										prod.node.slug && prod.node.slug.current
											? prod.node.slug.current
											: slugify(prod.node.title)
									return (
										<div key={prod.node.id}>
											<Link to={`/${slug}`}>
												<div className="product-box">
													<div className="product-image-wrapper">
														{prod.node.newProduct ? (
															<span className="product-status">New</span>
														) : null}
														<Img
															fluid={
																prod &&
																prod.node &&
																prod.node.productImage[0] &&
																prod.node.productImage[0].image &&
																prod.node.productImage[0].image.asset &&
																prod.node.productImage[0].image.asset.fluid
															}
															alt={prod.node.title}
														/>
														{/*
															<img
															src={
																prod &&
																prod.node &&
																prod.node.productImage[0] &&
																prod.node.productImage[0].image &&
																prod.node.productImage[0].image.asset &&
																prod.node.productImage[0].image.asset.fluid &&
																prod.node.productImage[0].image.asset.fluid.src
															}
															alt={prod.node.title}
														/>
														*/}
													</div>
													<h5 className="product-name text-body">
														{prod.node.title}
													</h5>
													{prod.node.discontinued ? (
														<p className="font-weight-bold mb-0">
															Discontinued
														</p>
													) : null}
													{prod.node._rawShortdescrption && (
														<div className="px-md-3 product-desc text-muted">
															<BlockContent
																blocks={prod.node._rawShortdescrptio}
															/>
														</div>
													)}
												</div>
											</Link>
										</div>
									)
								})}
							</Swiper>
						</LazyLoadComponent>
					</div>
				</div>
			</div>
		)
	}
}

export default FeaturedProducts
