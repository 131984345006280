import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Tab, Nav } from "react-bootstrap"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import HeroSlider from "@components/redesign/heroSlider"
import FeaturedProducts from "@components/redesign/featuredProducts"
// import AmericanLightingUniversity from "@components/redesign/americanLightingUniversity"
// import NewsEvents from "@components/redesign/newsEvents"

import "@components/redesign/redesign.scss"
import projectSupport from "@images/icon-projectsupport.svg"
import literatureMedia from "@images/icon-literature.svg"
import whereToBuy from "@images/icon-wheretobuy.svg"

class IndexPage extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const products = this.props.data.allSanityProduct.edges
    const slider = this.props.data.allSanityHomeBanner.edges
    const posts = this.props.data.allSanityPost.edges
    return (
      <Layout location={this.props.location} title={siteTitle} type="home">
        <SEO title="Home" description={siteDescription} />

        <HeroSlider slider={slider} />

        {/* TABS visible for tablet and desktop only*/}
        <LazyLoadComponent>
          <div className="d-none d-md-block">
            <Tab.Container defaultActiveKey={2}>
              <Nav className="home-tabs nav-justified">
                {/* <Nav.Item>
                  <Nav.Link eventKey={1}>
                    <div>News & Events</div>
                  </Nav.Link>
                </Nav.Item>*/}
                <Nav.Item>
                  <Nav.Link eventKey={2}>
                    <div>Featured Products</div>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey={3}>
                    <div>American Lighting University</div>
                  </Nav.Link>
                </Nav.Item>*/}
              </Nav>

              <Tab.Content>
                {/*<Tab.Pane eventKey={1}>
                  <LazyLoadComponent>
                    <NewsEvents posts={posts} />
                  </LazyLoadComponent>
                </Tab.Pane>*/}
                <Tab.Pane eventKey={2}>
                  <LazyLoadComponent>
                    <FeaturedProducts products={products} />
                  </LazyLoadComponent>
                </Tab.Pane>
                {/*<Tab.Pane eventKey={3}>
                  <LazyLoadComponent>
                    <AmericanLightingUniversity />
                  </LazyLoadComponent>
                </Tab.Pane>*/}
              </Tab.Content>
            </Tab.Container>
          </div>
        </LazyLoadComponent>

        {/*end of TABS for tablet and desktop*/}

        {/*FOR MOBILE*/}
        <LazyLoadComponent>
          <div className="d-block d-md-none">
            <LazyLoadComponent>
              <FeaturedProducts products={products} />
            </LazyLoadComponent>
            {/*<LazyLoadComponent>
              <NewsEvents posts={posts} />
            </LazyLoadComponent>
            <LazyLoadComponent>
              <AmericanLightingUniversity />
            </LazyLoadComponent>*/}
          </div>
          <div className="quick-links-section">
            <div className="container">
              <div className="row">
                <div className="col-md-4 mb-5">
                  <div className="button-bottom">
                    <div className="text-center">
                      <img
                        alt=""
                        src={projectSupport}
                        className="quick-links-icon"
                      />
                      <h3 className="quick-link-title">Project Support</h3>

                      <div className="button-wrapper">
                        <a
                          className="btn btn-secondary btn-sm btn-quick-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://app.smartsheet.com/b/form/e587f7bf17ce487ebe04eb09bf9a60a6"
                        >
                          Go to Project Support
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-5">
                  <div className="button-bottom">
                    <div className="text-center">
                      <img
                        alt=""
                        src={literatureMedia}
                        className="quick-links-icon"
                      />
                      <h3 className="quick-link-title">
                        Literature &amp; Media
                      </h3>

                      <div className="button-wrapper">
                        <Link
                          className="btn btn-secondary btn-sm btn-quick-link"
                          to="/literature-and-media"
                        >
                          Go to Media
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-5">
                  <div className="button-bottom">
                    <div className="text-center">
                      <img
                        alt=""
                        src={whereToBuy}
                        className="quick-links-icon"
                      />
                      <h3 className="quick-link-title">Where to Buy</h3>

                      <div className="button-wrapper">
                        <Link
                          className="btn btn-secondary btn-sm btn-quick-link"
                          to="/where-to-buy"
                        >
                          Go to Where to Buy
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LazyLoadComponent>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(
      sort: { fields: publishedAt, order: ASC }
      limit: 4
      filter: { whereToBuild: { eq: "live" } }
    ) {
      edges {
        node {
          id
          title
          whereToBuild
          slug {
            current
          }
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          authors {
            person {
              name
            }
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
          authors {
            person {
              id
              name
            }
          }
        }
      }
    }

    allSanityEvent(limit: 3) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
          categories
        }
      }
    }

    allSanityProduct(
      limit: 4
      sort: { fields: title, order: ASC }
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          _rawShortdescrption
          slug {
            current
          }
          newProduct
          discontinued
          productImage {
            image {
              asset {
                fluid(maxHeight: 316, maxWidth: 316) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    allSanityHomeBanner(
      filter: { whereToPublish: { eq: "live" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          title
          whereToPublish
          description
          animation
          banner {
            asset {
              fluid(maxWidth: 1440) {
                src
              }
            }
          }
          pagelinkname
          pagelink {
            current
          }
          productImage {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`
